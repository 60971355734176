/* You can add global styles to this file, and also import other style files */

html, body, header, #intro {
    height: 100%;
}

body {
    margin: 0;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
}

.view {
    position: relative;
    cursor: default;
}

.view .mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
}

.rgba-black-strong {
    background-color: rgba(0, 0, 0, .3);
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.shadow {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
}

/* Text */

.white-text {
    color: white;
}

.indigo-text {
    color: #3f51b5!important;
}

.blue-text {
    color: #2196f3!important;
}

.grey-text {
    color: #3b3e40!important;
}

.cyan-text {
    color: #00bcd4!important;
}

.green-text {
    color: #A4C639 !important;
}

.seperate {
    height: 30px;
}

.hr-light {
    border-top: 1px solid #fff;
}

/* Button */

.btn {
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
    margin: .375rem;
    border-radius: .125rem;
}

.btn.btn-ml {
    padding: 1.7rem 2.6rem;
    font-size: 1.7rem;
}

.btn.btn-md {
    padding: .7rem 1.6rem;
    font-size: .7rem;
}

.btn-indigo {
    background-color: #3f51b5!important;
    color: #fff!important;
}

.btn-green {
    background-color: #a4c639!important;
    color: #fff!important;
}

.btn-white {
    background-color: #fff!important;
    color: #000!important;
}

.btn-outline-white {
    border: 2px solid #fff!important;
    background-color: transparent!important;
    color: #fff!important;
}

.btn:hover {
    -webkit-box-shadow: 0 10px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
}

a.waves-effect, a.waves-light {
    display: inline-block;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
}

/* For pages without background image (stops overlapping navbar) */

.withoutBackgroundPicture {
    padding-top: 70px;
}

.content-section {
    padding: 50px 0;
    margin: 0 auto;
    width: 80%;
}

p {
    color: #3b3e40;
    font-size: 16px;
    line-height: 24px;
    margin: 1em 0;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/* Card Style */

.pricing-card {
    text-align: center;
}

.pricing-card-pro {
    background-image: url("assets/pricing-table.jpg");
    color: #fff;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    width: 100%;
}

.card-body {
    padding-bottom: 60px;
}

.card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.pricing-card .striped li {
    border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
    margin-bottom: 1rem;
}

.price {
    font-size: 4rem;
    padding: 1.5rem;
    font-weight: 300;
}

.move-element-up:hover {
    -webkit-box-shadow: 0 10px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
    transform: translateY(-20px);
}

.over {
    background: rgba(63, 81, 181, 0.7);
    height: 100%;
}

.entry-wrap {
    background: #eee;
}

.icon-list {
    padding-left: 0;
    margin-left: 24px;
    list-style-type: none;
}

i.fa.fa-check.green-text {
    margin-right: 5px;
}

.ng-valid[required], .ng-valid.required {
    border-left: 5px solid #42A948;
}

.ng-invalid:not(form) {
    border-left: 5px solid #a94442;
}

.lh-1 {
    line-height: 1.1;
}

/* lato-regular - latin_latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/lato-v15-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url('assets/fonts/lato-v15-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('assets/fonts/lato-v15-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('assets/fonts/lato-v15-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
         url('assets/fonts/lato-v15-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('assets/fonts/lato-v15-latin_latin-ext-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-italic - latin_latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('assets/fonts/lato-v15-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
    src: local('Lato Italic'), local('Lato-Italic'),
         url('assets/fonts/lato-v15-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('assets/fonts/lato-v15-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('assets/fonts/lato-v15-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
         url('assets/fonts/lato-v15-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('assets/fonts/lato-v15-latin_latin-ext-italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
